import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import {
  Container,
  Collapse,
  Navbar,
  Nav,
  NavItem,
  NavLink,
  NavbarToggler
} from 'reactstrap';

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      banner: file(relativePath: {eq: "images/logo.png"}, sourceInstanceName: {eq: "home"}) {
        childImageSharp {
          fixed(height: 50, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const banner = data.banner.childImageSharp.fixed

  return (
    <Navbar color="light" light fixed="true" expand="md">
      <Container>
        <Link to="/" className="navbar-brand">
          {/* <img src={logo} alt="Artoo" height="25px"/> */}
          <Img fixed={banner}/>
          {/* {data.site.siteMetadata.title} */}
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav className="justify-content-end" navbar>
            <NavItem>
              <NavLink href="/">Home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/blog/">Blog</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/trillers/">Trillers</NavLink>
            </NavItem>            
          </Nav>          
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header
