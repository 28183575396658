/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {ThemeProvider} from 'styled-components'
import {Container, Row, Col} from "reactstrap"

import theme from '../assets/stylesheets/theme'
import "../assets/stylesheets/layout.scss"

import Header from "./header"


const Layout = ({ children, color}) => {  

  let styleProps = {}
  if(color) {
    styleProps.background = color
  }

  return (
    <ThemeProvider theme={theme}>  
      <Header/>
      <main style={styleProps}>
        {children}        
      </main>
      <footer className="text-center">
        <Container>
          <Row>
            <Col className="align-text-center">            
              © {new Date().getFullYear()}, all rights reserved.
            </Col>
          </Row>                  
        </Container>        
      </footer>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
